import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import LoSentimos from '../components/pages/LoSentimos'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";
const IndexPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      pageContext={pageContext}
      location={location}
      companyName={pageContext.company}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <LoSentimos companyName={pageContext.company} pageContext={pageContext}/>
        </>
      )}
    />
  </GlobalFooterProvider>
)

export default IndexPage