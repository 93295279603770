import React, { useContext, useEffect ,useState} from 'react'
import { CardElevation } from '@findep/mf-landings-core'
import PropTypes from 'prop-types'
import CarouselImg from '../img/CarouselImg.compilable'
import { imageCss, TextHeader, TextBox } from '../../styles/lo-sentimos'
import { Grid } from '@material-ui/core';
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext'
import getTenant from '../../helpers/getTenant'

function LoSentimosCard({ data, companyName }) {

    const { setIsDynamic, setTenantFooter } = useContext(GlobalStateFooterContext);        
    const [ errorNip, setErrorNip] = useState(false)

    useEffect(async () => {
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId
        //GET TENANT                
        const tenantValue = await getTenant(id)
        setIsDynamic(true)
        setTenantFooter(tenantValue)
    }, [])

    useEffect(() => {
        sessionStorage.clear()
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores)
        setErrorNip(urlParams.has('nip-error'));
    }, [])

    return (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={6}>
                <br /> <br />

                <TextHeader>{errorNip ?'Lo sentimos el NIP ingresado fue erróneo es necesario comenzar un proceso nuevo.' :data.textHeader}</TextHeader>
                <br />
                <TextBox>
                    {!errorNip && (<>{data.textBox} <br /><br /></>)}
                    
                    {errorNip ? data.textBoxThree : data.textBoxTwo}
                    <br /><br />
                    {errorNip ? data.textBoxTwo : data.textBoxThree}
                    <br /><br />
                </TextBox>
                <br />
                <Grid container justify="center">
                    <Grid item>
                        {/* <ButtonDegrade
                            onClick={
                                () => navigate(`/encuesta-salida/`)
                            }
                            textButton="CONTESTAR ENCUESTA"
                        /> */}
                    </Grid>
                </Grid>
                <br />
                <CardElevation fullWidth noPadding>
                    <div css={imageCss}>
                        <CarouselImg filename="2.svg" company={companyName} />
                    </div>
                </CardElevation>

                <br /><br />&nbsp;&nbsp;&nbsp;
            </Grid>
        </Grid>
    )
}

LoSentimosCard.propTypes = {
    data: PropTypes.object.isRequired,
    companyName: PropTypes.string.isRequired
}

export default LoSentimosCard